export function modoApp(modo) {
  let modo_app = ''
  switch (modo) {
    case 'profissionais':
      modo_app = 'Profissionais'
      break;
    case 'estabelecimentos':
      modo_app = 'Estabelecimentos'
      break;
    case 'ambos':
      modo_app = 'Ambos'
      break;
    default:
      modo_app = ''
      break;
  }
  return modo_app
}
