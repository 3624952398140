/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Modal,
  Form,
  Button,
} from 'rsuite';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { InputDataRange } from '~/components/InputDataRange';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import { endOfWeek, startOfWeek, subDays } from 'date-fns';
import moment from 'moment';
import theme from '../../../styles/theme';

const GerarFaturasSemanais = ({ setCarregando, atualizarTabela }) => {
  const [exibir, setExibir] = useState(false);
  const [datas, setDatas] = useState([startOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }), endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 })]);

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const cancelSubmit = () => {
    setExibir(false);
  };

  const gerarBoletos = async () => {
    setCarregando(true);
    setExibir(false);
    const dataInicio = moment(datas[0]).format('YYYY-MM-DD');
    const dataFim = moment(datas[1]).format('YYYY-MM-DD');
    const data1 = [dataInicio, dataFim];
    try {
      const response = await api.post(`/pagamentos/gerar-fatura-semanal-stark`, {
        datas: data1
      })
      // if (response.data.error) {
      //   throw new Error(response.data.message);
      // }
      toast.success('Faturas geradas com sucesso!')
      // toast.success(response.data.message)
      setCarregando(false);
    } catch (err) {
      toast.error(err.message)
      setCarregando(false);
    }
    atualizarTabela()
    return true;
  };

  return (
    <>
      <ButtonFullBlue
        appearance="primary"
        onClick={() => setExibir(true)}
      >
        Gerar Faturas Semanais
      </ButtonFullBlue>
      <Modal
        open={exibir}
        onClose={() => {
          setExibir(false);
          cancelSubmit();
        }}
        size="xs"
      >
        <Form onSubmit={gerarBoletos}>
          <Modal.Header>
            <Modal.Title><h3>Gerar Faturas</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group>
              <Form.ControlLabel>Perído em que as Faturas serão geradas. </Form.ControlLabel>
              <InputDataRange
                style={{ marginRight: '15px', }}
                ranges={[]}
                isoWeek
                oneTap
                locale={locale}
                onClean={() => {
                  setDatas([]);
                }}
                onChange={(value) => {
                  setDatas(value)
                }}
                value={datas}
                hoverRange="week"
              // defaultValue={[startOfWeek(subDays(new Date(), 7)), endOfWeek(subDays(new Date(), 7))]}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => cancelSubmit()} appearance="subtle">
              Cancelar
            </Button>
            <Button
              appearance="primary"
              type="submit"
              style={{ background: `${theme.primaryColor}` }}
            >
              Gerar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
GerarFaturasSemanais.propTypes = {
  setCarregando: PropTypes.bool.isRequired,
};

export default GerarFaturasSemanais;
