/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext } from 'react';
import { Modal, Button } from 'rsuite';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { DarkModeContext } from '~/common/contexts/darkMode';
import PropTypes from 'prop-types';
import { ButtonBorderBlue } from '../../../../components/Buttons/ButtonBorderBlue/styles';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

function FinalizarPedidosConfirmado({ buscarPedidos, total, selectedParams }) {
  const [exibir, setExibir] = useState(false);
  const { toggleMode } = useContext(DarkModeContext)
  async function autorizarPedidos() {
    try {
      const params = { ...selectedParams }
      await api.patch('/pedidos/finalizar-em-massa', selectedParams
      );
      buscarPedidos();
      toast.success('Pedidos Finalizados com Sucesso');
    } catch (error) {
      toast.error(error.message);
    }
    setExibir(false);
  }

  let temPedidoConfirmado = false;
  const statusParams = selectedParams.status
  if (Array.isArray(statusParams)) {
    temPedidoConfirmado = statusParams.some((el) => el === 'Confirmado');
  } else {
    temPedidoConfirmado = false;
  }

  return (
    <>
      <ButtonBorderBlue appearance="ghost" onClick={() => setExibir(true)} darkMode={toggleMode}>
        Finalizar Pedidos
      </ButtonBorderBlue>
      <Modal open={exibir} onClose={() => setExibir(false)} size="sm">
        <Modal.Header>
          <Modal.Title>Finalizar Pedidos Confirmados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!temPedidoConfirmado || total === 0
            ? <h5>Só é possível finalizar pedidos com o status de confirmado.</h5>
            : <h5>Tem certeza que deseja finalizar todos os {total} pedidos em confirmado?</h5>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setExibir(false)} appearance="subtle">
            Cancelar
          </Button>
          {temPedidoConfirmado && total > 0 &&

            <ButtonFullBlue
              appearance="primary"
              onClick={() => autorizarPedidos()}
            >
              Finalizar
            </ButtonFullBlue>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}

FinalizarPedidosConfirmado.propTypes = {
  buscarPedidos: PropTypes.func.isRequired,
  total: PropTypes.number,
  selectedParams: PropTypes.shape({
    pagina: PropTypes.number,
    data: PropTypes.arrayOf.isRequired,
    filtroServico: PropTypes.number,
    idEstabelecimento: PropTypes.number,
    cidade: PropTypes.arrayOf.isRequired,
    idProfissional: PropTypes.number,
    status: PropTypes.string,
  }).isRequired,
};

FinalizarPedidosConfirmado.defaultProps = {
  total: 0,
};

export default FinalizarPedidosConfirmado;
