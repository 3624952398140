import React from 'react';

import { Switch } from 'react-router-dom';

import RecuperarSenha from '~/pages/RecuperarSenha';
import Route from './Route';

import Login from '../pages/Login';
import Home from '../pages/Home';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/recuperar-senha/:tipo" exact component={RecuperarSenha} />
      <Route path="/home" component={Home} isPrivate />

      <Route path="/" component={() => 
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <h1>ERRO: 404</h1>
        <h1>Página não encontrada</h1>
        </div>} />
    </Switch>
  );
}
