/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Table, IconButton } from 'rsuite';

import PropTypes from 'prop-types';
import { FileDownload } from '@rsuite/icons';
import theme from '../../../../styles/theme';

const { Cell } = Table;

export const fundo = (data) => {
  let cor;
  if (data.status_invoice && data.status_boleto === 'Pendente' || data.status_invoice === 'Pendente' && data.status_boleto === 'Criado') {
    cor = theme.yellowColor;
  } else if (data.status_boleto === 'Pago' || data.status_invoice === 'Pago') {
    cor = theme.darkGreen;
  } else if (data.status_invoice && data.status_boleto === 'Cancelado') {
    cor = theme.lightRed;
  } else if (data.status === 'Parcialmente Pago') {
    cor = theme.lightGreen;
  } else if (data.status === 'Devolvido') {
    cor = theme.darkRed;
  } else if (data.status === 'Expirado') {
    cor = theme.darkGray;
  } else if (data.status === 'Autorizado') {
    cor = theme.primaryColor;
  }
  return cor;
};

export const texto = (data) => {
  let cor;
  if (data.status === 'Confirmado' && data.checkin === 'Realizado') {
    cor = '#fafafa';
  }
  if (data.status_invoice && data.status_boleto === 'Cancelado') {
    cor = '#fafafa';
  } else if (data.status === 'Finalizado') {
    cor = '#fafafa';
  }
  return cor;
};

export const CelulaAcaoBaixarPdf = ({ rowData, onClick, toggleMode, celulaStark, ...props }) => (
  <Cell
    {...props}
    onClick={() => onClick(rowData)}
    style={{
      background: celulaStark ? fundo(rowData) : rowData.status === 'Pago' && `${theme.darkGreen}`,
      padding: '6px 0',
    }}
  >
    <IconButton
      appearance="link"
      // style={{ color: (rowData.status === 'Pago' || toggleMode) ? `${theme.whiteColor}` : '#575757' }}
      style={{ color: (rowData.status === 'Pago' || toggleMode) ? `${theme.whiteColor}` : '#fff' }}
      icon={<FileDownload />}
    />
  </Cell>
);

CelulaAcaoBaixarPdf.propTypes = {
  rowData: PropTypes.object,
  celulaStark: PropTypes.bool,
  onClick: PropTypes.func,
  toggleMode: PropTypes.bool,
};

CelulaAcaoBaixarPdf.defaultProps = {
  toggleMode: false,
  celulaStark: false,
  rowData: {},
  onClick: () => { },
};
