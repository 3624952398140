/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Edit, Check, Trash } from '@rsuite/icons';

import { Table, IconButton, Divider, Popover, Whisper, SelectPicker, InputPicker } from 'rsuite';
import { ResponsiveForm } from '../../ResponsiveForm';

const { Cell } = Table;

function CelulaEditavel({ rowData, dataKey, onChange, ...props }) {
  const editing = rowData.status === 'EDIT' && dataKey !== 'id';
  return (
    <Cell {...props}>
      {editing ? (
        <input
          className="rs-input"
          defaultValue={rowData[dataKey]}
          onChange={(event) => {
            onChange(rowData.id, dataKey, event.target.value);
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
}

function CelulaEditavelSelectPicker({ rowData, dataKey, onChange, ...props }) {
  const editing = rowData.status === 'EDIT' && dataKey !== 'id';
  return (
    <Cell {...props}>
      {editing ? (
        <InputPicker
          placeholder={rowData[dataKey]}
          data={[{
            label: 'Profissionais',
            value: 'profissionais',
          },
          {
            label: 'Estabelecimentos',
            value: 'estabelecimentos',
          },
          {
            label: 'Ambos',
            value: 'ambos',
          }
          ]}
          onSelect={(event) => {
            onChange(rowData.id, 'modo_app', event);
          }}
          style={{ width: 224 }} />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
}

function CelulaAcao({ rowData, onClick, onDelete, ...props }) {
  return (
    <Cell {...props} style={{ padding: '6px 0' }}>
      {rowData.status === 'EDIT' ? (
        <IconButton
          appearance="subtle"
          onClick={() => onClick(rowData.id)}
          icon={<Check />}
        />
      ) : (
        <IconButton
          appearance="subtle"
          onClick={() => onClick(rowData.id)}
          icon={<Edit />}
        />
      )}
      {onDelete && <Divider vertical />}
      {onDelete && (
        <IconButton
          appearance="subtle"
          onClick={() => onDelete(rowData)}
          color="red"
          icon={<Trash />}
        />
      )}
    </Cell>
  );
}

function CelulaEditavelDescricao({ rowData, dataKey, onChange, ...props }) {
  const editing = rowData.status === 'EDIT' && dataKey !== 'id';
  const speaker = (
    <Popover title="Descrição">
      <p>
        {rowData[dataKey]}
      </p>
    </Popover>
  );
  return (
    <Cell {...props}>
      {editing ? (

        <textarea
          style={{ marginTop: '-10px' }}
          className="rs-input"
          defaultValue={rowData[dataKey]}
          onChange={(event) => {
            onChange(rowData.id, dataKey, event.target.value);
          }}
        />
      ) : (
        <Whisper placement="top" speaker={speaker}>
          <span className="table-content-edit-span">{rowData[dataKey]}</span>
        </Whisper>
      )}
    </Cell>
  );
}

CelulaEditavel.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaEditavel.defaultProps = {
  rowData: {},
  // eslint-disable-next-line prettier/prettier
  onChange: () => { },
};

CelulaEditavelSelectPicker.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaEditavelSelectPicker.defaultProps = {
  rowData: {},
  // eslint-disable-next-line prettier/prettier
  onChange: () => { },
};

CelulaEditavelDescricao.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaEditavelDescricao.defaultProps = {
  rowData: {},
  // eslint-disable-next-line prettier/prettier
  onChange: () => { },
};

CelulaAcao.propTypes = {
  rowData: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

CelulaAcao.defaultProps = {
  rowData: {},
  onDelete: null,
};

export { CelulaEditavel, CelulaEditavelDescricao, CelulaAcao, CelulaEditavelSelectPicker };
